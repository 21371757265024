import React from 'react'
import logo from './logo.svg'
import Footer from './Footer'
import Starfield from './Starfield'
import './Content.css'

function Content() {
	return (
        <>
            <div className="wrapper">
                <div className="container vert-flex center-flex">
                    <img className="logo" src={logo} alt="logo" />
                    <h1>COMING SOON</h1>
                    <p>We are about to setup this site...</p>
                    <p>Stay in touch!</p>
                </div>
            </div>
            <Footer />
            <Starfield />
        </>
	)
}

export default Content
