import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className="footer-sticky center">
        <span>315solutions - From Berlin with ❤ </span>
    </div>
  )
}

export default Footer
